<template>
  <div id="login">
    <!-- <PasswordReset v-if="showPasswordReset" @close="togglePasswordReset()"></PasswordReset> -->
    <section>
      <div :class="{ 'signup-form': !showLoginForm }" class="col2 ml-3 mr-3">
        <form v-if="showLoginForm" @submit.prevent>
          <div>
            <b-field label="Email">
              <b-input
                v-model.trim="loginForm.email"
                type="email"
                value=""
                maxlength="30"
              >
              </b-input>
            </b-field>
          </div>
          <div>
            <b-field label="Password">
              <b-input
                v-model.trim="loginForm.password"
                value=""
                type="password"
                maxlength="30"
              ></b-input>
              <b-loading
                :is-full-page="isFullPage"
                v-model="isLoading"
                :can-cancel="true"
              ></b-loading>
            </b-field>
          </div>
          <b-button type="is-success" @click="login()">Log In</b-button>
          <b-button type="is-info" @click="toggleForm()" class="ml-6"
            >Create an Account</b-button
          >
          <div class="extras mt-4">
            <!-- <a @click="togglePasswordReset()">Forgot Password</a> -->
            <!-- <b-button @click="toggleForm()">Create an Account</b-button> -->
          </div>
        </form>
        <form v-else @submit.prevent>
          <!-- <h1>Get Started</h1> -->
          <div>
            <label for="name">Name</label>
            <b-input v-model.trim="signupForm.name" type="text" id="name" />
          </div>
          <div>
            <label for="email2">Email</label>
            <b-input v-model.trim="signupForm.email" type="text" id="email2" />
          </div>
          <div>
            <label for="password2">Password</label>
            <b-input
              v-model.trim="signupForm.password"
              type="password"
              placeholder="min 6 characters"
              id="password2"
            />
          </div>
          <!-- <div class="mb-4">
            <label label="team">Team</label>
            <b-select v-model="signupForm.team" placeholder="Select a team">
              <option
                v-for="option in teamList"
                :value="option.id"
                :key="option.name"
              >
                {{ option.name }}
              </option>
            </b-select>
          </div> -->
          <!-- </div> -->
          <b-button @click="signup()" type="is-success">Sign Up</b-button>
          <b-button
            @click="toggleForm()"
            type="is-info"
            class="ml-6"
            icon-left="arrow-left"
          >
            Back to Log In</b-button
          >
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  mounted() {},
  data() {
    return {
      loginForm: {
        email: "",
        password: ""
      },
      signupForm: {
        name: "",
        team: "",
        email: "",
        password: ""
      },
      showLoginForm: true,
      isLoading: false,
      isFullPage: true
    };
  },
  computed: {
    ...mapState(["teamList"])
  },
  methods: {
    login() {
      this.$store.dispatch("login", {
        email: this.loginForm.email,
        password: this.loginForm.password
      });
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 4 * 1000);
      this.loginForm.email = "";
      this.loginForm.password = "";
    },
    signup() {
      this.$store.dispatch("signup", {
        email: this.signupForm.email,
        password: this.signupForm.password,
        name: this.signupForm.name
        // team: this.signupForm.team
      });
    },
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
    }
    // getteams() {
    //   this.$store.dispatch("fetchTeams");
    // }
  }
};
</script>
